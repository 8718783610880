<template>
  <header class="header border-bottom">
    <h5 class="m-0">Total - {{ totalBranches }}</h5>
    <img class="logo" :src="logo" alt="Weapp" />
    <b-button @click="logout()" class="logout custom-btn" id="logout">
      <i class="logout-icon las la-sign-out-alt"></i>
    </b-button>
    <b-tooltip target="logout" title="Sair"></b-tooltip>
  </header>
</template>

<script>
import {
  removeActiveBranch,
  removeActiveCompany,
  removeActiveUser
} from "@/helpers/storage";

export default {
  name: "sm-header",
  computed: {
    logo() {
      return this.$store.state.logo;
    }
  },
  props: ["totalBranches"],
  methods: {
    logout() {
      this.$store.dispatch("auth/logout").then(() => {
        this.$router.push("/");
        removeActiveBranch();
        removeActiveCompany();
        removeActiveUser();
        window.location.reload();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  position: fixed;
  background-color: #fff;
  top: 0;
  min-height: 70px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  align-items: center;
  justify-content: space-between;
  padding: 15px 30px;
  z-index: 1000;
}

.logo {
  max-width: 180px;
  justify-self: center;
}

.logout {
  justify-self: end;
}

.logout-icon {
  font-size: 1.4rem;
}
</style>
