// src/store/services/users.js
import feathersClient, {
  makeServicePlugin,
  BaseModel
} from "../../feathers-client";

class Order extends BaseModel {
  constructor(data, options) {
    super(data, options);
  }
  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = "Order";
  // Define default properties here
  static instanceDefaults() {
    return {
      _id: "",
      amount: 0.0,
      branch: "",
      change: 0,
      company: "",
      createdAt: Date,
      customer: "",
      deliveryFee: 0.0,
      earnedPoints: 0,
      gifts: [],
      history: [],
      items: [],
      location: [],
      locationType: 0,
      offers: [],
      paid: false,
      paymentMethod: {},
      review: {},
      seq: 0,
      serviceFee: 0.0,
      status: 0,
      subtotal: 0.0,
      total: 0.0,
      updatedAt: Date,
      withdrawalTime: "",
      appInfo: {}
    };
  }
}
const servicePath = "manager/orders";
const servicePlugin = makeServicePlugin({
  Model: Order,
  service: feathersClient.service(servicePath),
  servicePath
});

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
});

export default servicePlugin;
