// src/store/services/users.js
import feathersClient, {
  makeServicePlugin,
  BaseModel
} from "../../feathers-client";

class Product extends BaseModel {
  constructor(data, options) {
    super(data, options);
  }
  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = "Product";
  // Define default properties here
  static instanceDefaults() {
    return {
      _id: "",
      active: true,
      branch: "",
      company: "",
      complementGroup: "",
      complementsGroups: [],
      flags: {},
      locationTypes: [],
      name: "",
      period: {},
      preparationTime: 0,
      price: 0,
      sliderHeader: {},
      staticImage: [],
      stock: {},
      description: "",
      relatedPeriod: "",
      code: ""
    };
  }
}
const servicePath = "manager/products";
const servicePlugin = makeServicePlugin({
  Model: Product,
  service: feathersClient.service(servicePath),
  servicePath
});

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
});

export default servicePlugin;
