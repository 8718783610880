// src/store/services/users.js
import feathersClient, {
  makeServicePlugin,
  BaseModel
} from "../../feathers-client";

class ComplementGroup extends BaseModel {
  constructor(data, options) {
    super(data, options);
  }
  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = "ComplementGroup";
  // Define default properties here
  static instanceDefaults() {
    return {
      _id: "",
      branch: "",
      description: "",
      items: [],
      kind: "",
      locationTypes: [],
      rules: {},
      title: "",
      minPrice: 0,
      min: 0,
      max: 10,
      mandatory: false,
      quantity: 0
    };
  }
}
const servicePath = "manager/complements-groups";
const servicePlugin = makeServicePlugin({
  Model: ComplementGroup,
  service: feathersClient.service(servicePath),
  servicePath
});

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
});

export default servicePlugin;
