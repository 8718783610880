<template>
  <main>
    <TheHeader :totalBranches="getTotalCompanies()" />
    <main>
      <div v-if="loading" class="center">
        <pacman-loader color="#7429f5">loading</pacman-loader>
      </div>
      <section class="container section-gutter" v-else>
        <b-table-simple class="text-center" hover responsive>
          <b-thead head-variant="dark">
            <b-tr>
              <b-th v-for="(field, index) in fields" :key="index">{{
                field
              }}</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr
              v-for="(company, index) in filteredCompanies"
              :key="company._id"
            >
              <CompanyModal
                ref="companyModal"
                :loading="processing"
                :loadingBranch="loadingBranch"
                :disabled="disabled"
                :disabledBranch="disabledBranch"
                :company="company"
                :loadingButton="loadingButton"
                @save-company="saveCompany"
                @handle-branch-submit="patchBranch"
                @save-pagseguro="savePagseguro"
                @save-iZettle="saveiZettle"
              />
              <b-td>{{ index + 1 }}</b-td>
              <b-td>{{ company.name }}</b-td>
              <b-td>{{ company.slug }}</b-td>
              <b-td>{{ company.branches.length }}</b-td>
              <b-td>{{ formatDate(company.createdAt) }}</b-td>
              <b-td
                :class="
                  company.isCompanyActive ? 'text-success' : 'text-danger'
                "
                >{{ isActive(company.isCompanyActive) }}</b-td
              >
              <b-td>
                <custom-switch
                  @toggled="patchCompany"
                  :id="company._id"
                  :active="company.isCompanyActive"
                />
                <i
                  class="las la-pen company-icon ml-2"
                  @click="$bvModal.show(company._id)"
                />
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
        <div class="d-flex justify-content-end">
          <b-pagination
            class="mt-3"
            pills
            v-model="currentPage"
            :total-rows="getTotalCompanies()"
            :per-page="perPage"
            v-if="!search"
          ></b-pagination>
        </div>
      </section>
    </main>
    <TheFooter @search-text="setSearch" @new-company="newCompany" />
  </main>
</template>

<script>
import TheHeader from "@/components/TheHeader";
import TheFooter from "@/components/TheFooter";
import CustomSwitch from "@/components/Utils/CustomSwitch";
import CompanyModal from "@/components/CompanyModal";
import { showToast } from "../../helpers/toast";

import { getAccessToken } from "@/helpers/storage";
import { getCompanies } from "@/helpers/queries";
import Locations from "@/network/locations";

import { patch, post, postPayments } from "@/network/rest";

import PacmanLoader from "vue-spinner/src/PacmanLoader";
import moment from "moment";

export default {
  components: {
    TheHeader,
    TheFooter,
    PacmanLoader,
    CustomSwitch,
    CompanyModal,
  },
  computed: {
    accessToken: () => getAccessToken(),
    feathers() {
      return this.$FeathersVuex.api;
    },
    indexOfLastBranch() {
      return this.currentPage * this.perPage;
    },
    indexOfFirstBranch() {
      return this.indexOfLastBranch - this.perPage;
    },
    currentCompanies() {
      return this.companies.slice(
        this.indexOfFirstBranch,
        this.indexOfLastBranch
      );
    },
    companies() {
      return this.feathers.Company.findInStore({}).data;
    },
    filteredCompanies() {
      if (this.search !== "") {
        return this.companies.filter((company) => {
          if (company.name || company.slug) {
            return (
              company.name.toLowerCase().includes(this.search.toLowerCase()) ||
              company.slug.toLowerCase().includes(this.search.toLowerCase())
            );
          } else {
            return false;
          }
        });
      } else {
        return this.currentCompanies;
      }
    },
  },
  data() {
    return {
      loading: false,
      loadingBranch: false,
      processing: false,
      disabled: false,
      disabledBranch: false,
      loadingButton: false,
      fields: [
        "#",
        "Nome",
        "Slug",
        "Filiais",
        "Data de criação",
        "Ativa?",
        "Ativar / Editar",
      ],
      currentPage: 1,
      perPage: 20,
      search: "",
    };
  },
  methods: {
    isActive(isCompanyActive) {
      return isCompanyActive ? "Sim" : "Não";
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    getTotalCompanies() {
      const companies = this.feathers.Company.findInStore({});
      return companies.total;
    },
    async patchCompany(data) {
      await patch(this.accessToken, `${Locations.companies}/${data.id}`, {
        isCompanyActive: data.checked,
      });
    },
    async saveCompany(company) {
      await patch(
        this.accessToken,
        `${Locations.companies}/${company._id}`,
        company
      );
    },
    setSearch(data) {
      this.search = data;
    },
    async patchBranch(data) {
      try {
        this.loadingBranch = true;
        this.disabledBranch = true;
        if (data.mercadopago) {
          await post(this.accessToken, Locations.confirmationCode, {
            id: data.id,
            code: data.mercadopago,
          });
        }

        await patch(this.accessToken, `${Locations.branches}/${data.id}`, data);

        if (data.beeCnpj) {
          await post(this.accessToken, Locations.integrateBee, {
            id: data.id,
            doc: data.beeCnpj,
          });
        }
        showToast(this, `Branch atualizada com sucesso`, "success");
      } catch (e) {
        console.log(e);
        showToast(this, `Falha ao atualizar branch`, "danger");
      } finally {
        this.loadingBranch = false;
        this.disabledBranch = false;
      }
    },
    async savePagseguro(data) {
      await patch(this.accessToken, `${Locations.branches}/${data.branch}`, {
        gateways: {
          pagseguro: data.ps,
        },
      });

      this.$bvModal.hide(`pagseguro-modal-${data.branch}`);
    },
    async saveiZettle(data) {
      this.loadingButton = true;
      let encodedToken = "";
      try {
        if (!data.encodedToken) {
          const response = await postPayments(
            this.accessToken,
            Locations.payments.iZettle.encodeToken,
            {
              jwtiZettle: data.newToken,
              keySecret: process.env.VUE_APP_IZETTLE_AUTH_ENCODE_KEY_ROUTE,
            }
          );
          encodedToken = response.data.encodedJWT;
        } else {
          encodedToken = data.encodedToken;
        }

        await patch(
          this.accessToken,
          `${Locations.branches}/${data.branch}`,
          {
            "gateways.iZettle": {
              token: encodedToken,
              active: data.active,
            },
          },
          this.feathers.Branch
        );
        showToast(this, `Token iZettle vinculado com sucesso`, "success");
      } catch (e) {
        console.log(e);
        showToast(
          this,
          `Não foi possível vincular token iZettle ${failMsg}`,
          "danger"
        );
      }
      this.loadingButton = false;
    },
    async newCompany(company) {
      await post(this.accessToken, Locations.companies, company);

      this.$bvModal.hide("new-company-modal");
    },
  },
  async created() {
    this.loading = true;

    await getCompanies(
      this.feathers.Company,
      this.accessToken,
      Locations.companies
    );

    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
main {
  margin-top: 100px;
}

.center {
  height: calc(100vh - 140px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.section-gutter {
  padding-bottom: 100px;
}

.company-icon {
  font-size: 2rem;
  color: $accent-color;
  cursor: pointer;
}
</style>
