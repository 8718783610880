const login = (store, email, password) =>
  store.dispatch("auth/authenticate", {
    strategy: "local",
    username: email,
    password: password,
    userStrategy: "superuser"
  });

const autologin = store => store.dispatch("auth/authenticate");

export { login, autologin };
