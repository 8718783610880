const toast = (...params) => {
  const [context, title, message, autoHideDelay, variant] = params;
  context.$bvToast.toast(message, {
    title,
    variant,
    autoHideDelay
  });
};

const showToast = (context, message, variant) => {
  if (variant === "success") {
    toast(context, "Mensagem", message, 3000, variant);
  } else {
    toast(context, "Ocorreu um erro", message, 3000, variant);
  }
};

export { toast, showToast };
