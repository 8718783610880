<template>
  <b-modal
    size="lg"
    :id="`TotemUser-modal-${totemUser._id}`"
    title="Usuario totem"
    @hide="closeModal()"
  >
    <b-form @submit.prevent="handleSubmit(totemUser._id)">
      <custom-switch
        :label="userTotem.active ? 'Ativado' : 'Inativo '"
        id="active"
        :active="userTotem.active"
        style="float:right;top:-20px"
        @toggled="
          ({ checked }) => {
            userTotem.active = checked;
            addInPatchObject('active');
          }
        "
      />
      <b-list-group horizontal class="mt-4">
        <span class="mt-3 mr-3">
          Nome:
        </span>
        <b-input
          :required="totemUser._id === 'new'"
          type="text"
          placeholder="Nome completo"
          class="mt-2 w-75"
          v-model="userTotem.name"
          @change="addInPatchObject('name')"
        />
      </b-list-group>

      <b-list-group horizontal>
        <span class="mt-3 mr-3">
          Email:
        </span>
        <b-input
          :required="totemUser._id === 'new'"
          type="email"
          placeholder="Email"
          class="mt-2 w-50"
          v-model="userTotem.email"
          @change="addInPatchObject('email')"
        />
      </b-list-group>

      <b-list-group horizontal>
        <span class="mt-3 mr-3">
          Logins permitidos:
        </span>
        <b-input
          :required="totemUser._id === 'new'"
          type="number"
          placeholder="Máximo de logins simultâneos"
          class="mt-2 w-25"
          v-model="userTotem.maxConcurrentLogins"
          @change="addInPatchObject('maxConcurrentLogins')"
          min="1"
        />
      </b-list-group>

      <b-list-group horizontal>
        <ToolTip
          v-if="userTotem._id !== 'new'"
          id="passwordChange"
          message="Deixe ambos os campos de senha vazios, caso não deseje alterar a senha. Obs. Alterar a senha invalidará todos os tokens de acesso gerados anteriormente com esse usuário"
          class="mt-2"
        />
        <span class="mt-3 mr-3">
          Senha:
        </span>
        <b-input
          :required="totemUser._id === 'new'"
          :type="passwordFieldType"
          ref="password"
          placeholder="Senha"
          class="mt-2 mr-3"
          v-model="password"
        />
        <i
          class="la la-eye-slash icon mt-3"
          ref="passwordVisibilityIcon"
          @click="
            toggleVisibilityPassword(
              'passwordVisibilityIcon',
              'passwordFieldType'
            )
          "
        />
        <span class="mt-2 mr-3 ml-3">
          Confirmar senha:
        </span>
        <b-input
          :required="totemUser._id === 'new'"
          :type="confirmPasswordFieldType"
          placeholder="Confimação de senha"
          class="mt-2 mr-3 ml-3"
          v-model="confirmPassword"
          ref="confirmPassword"
        />
        <i
          class="la la-eye-slash icon mt-3"
          ref="confirmPasswordVisibilityIcon"
          @click="
            toggleVisibilityPassword(
              'confirmPasswordVisibilityIcon',
              'confirmPasswordFieldType'
            )
          "
        />
      </b-list-group>
      <div class="custom-modal-footer">
        <b-button :disabled="loading" type="submit" class="custom-btn">
          <div
            v-if="loading"
            class="spinner-border text-white spinner-border-sm"
          ></div>
          <span v-else>Salvar</span>
        </b-button>
      </div>
    </b-form>
  </b-modal>
</template>

<script>
import CustomSwitch from "@/components/Utils/CustomSwitch";
import ToolTip from "@/components/Utils/ToolTip";
import { showToast } from "@/helpers/toast";
import { post, patch } from "@/network/rest";
import { getAccessToken } from "@/helpers/storage";
import Locations from "@/network/locations";

export default {
  name: "TotemUserModal",
  components: {
    CustomSwitch,
    ToolTip,
  },
  props: {
    totemUser: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      passwordFieldType: "password",
      confirmPasswordFieldType: "password",
      loading: false,
      password: "",
      confirmPassword: "",
      userTotem: {},
      patchObject: {},
    };
  },
  created() {
    this.userTotem = JSON.parse(JSON.stringify(this.totemUser));
  },
  methods: {
    resetFields() {
      this.password = "";
      this.confirmPassword = "";
      this.patchObject = {};
    },
    closeModal() {
      this.userTotem = JSON.parse(JSON.stringify(this.totemUser));
      this.resetFields();
    },
    toggleVisibilityPassword(refIcon, inputField) {
      this[inputField] = this[inputField] === "text" ? "password" : "text";

      this.$refs[refIcon].className =
        this.$refs[refIcon].className === "la la-eye icon mt-3"
          ? "la la-eye-slash icon mt-3"
          : "la la-eye icon mt-3";
    },
    addInPatchObject(field) {
      console.log("aqui", field);
      this.patchObject[field] = this.userTotem[field];
      console.log(this.patchObject);
    },
    async handleSubmit(id) {
      id === "new" ? this.createTotemUser() : this.patchTotemUser();
    },
    async patchTotemUser() {
      console.log(this.patchObject, this.password, this.confirmPassword);
      if (
        !Object.entries(this.patchObject).length &&
        !(this.password || this.confirmPassword)
      ) {
        console.log("entrou errado", !Object.entries(this.patchObject).length);
        return;
      }
      console.log("passou do errado");

      this.loading = true;
      if (
        this.patchObject.maxConcurrentLogins &&
        typeof this.patchObject.maxConcurrentLogins === "string"
      ) {
        this.patchObject.maxConcurrentLogins = Number(
          this.patchObject.maxConcurrentLogins
        );
      }

      if (this.password || this.confirmPassword) {
        if (this.password === this.confirmPassword) {
          this.patchObject.password = this.password;
          this.patchObject.iatVerify = [];
        } else {
          showToast(
            this,
            "È necessário que a senha seja digitada igual nos dois campos. Para não altera-la basta deixar ambos os campos vazios",
            "danger"
          );
          this.loading = false;
          return;
        }
      }
      try {
        await patch(
          getAccessToken(),
          `${Locations.TotemUsers}/${this.userTotem._id}`,
          this.patchObject
        );
        showToast(this, `usuário atualizado com sucesso`, "success");
        this.loading = false;
      } catch (e) {
        console.log(e);
        showToast(this, "Erro ao atualizar usuário", "danger");
        this.loading = false;
      }
      this.resetFields();
    },
    async createTotemUser() {
      try {
        if (this.password !== this.confirmPassword) {
          showToast(this, "Digite a mesma senha em ambos os campos", "danger");
          return;
        } else {
          this.loading = true;
          const newUserTotem = JSON.parse(JSON.stringify(this.userTotem));
          delete newUserTotem._id;
          newUserTotem.password = this.password;
          newUserTotem.maxConcurrentLogins = Number(
            newUserTotem.maxConcurrentLogins
          );
          await post(getAccessToken(), Locations.TotemUsers, newUserTotem);
          showToast(this, `Usuário criado com sucesso`, "success");
          this.loading = false;
          this.$bvModal.hide(`TotemUser-modal-new`);
          return;
        }
      } catch (e) {
        this.loading = false;
        console.log(e);
        showToast(this, `Ocorreu algum erro ao criar usuário`, "danger");
      }
    },
  },
  watch: {
    totemUser: {
      immediate: true,
      deep: true,
      handler() {
        this.userTotem = JSON.parse(JSON.stringify(this.totemUser));
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.icon {
  font-size: 1.7em;
  height: 7px;
}
.icon:hover {
  cursor: pointer;
  color: grey;
}
.icon:active {
  color: gainsboro;
}
</style>
