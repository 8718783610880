const set = (name, data) => localStorage.setItem(name, data);
const get = name => localStorage.getItem(name);
const remove = name => localStorage.removeItem(name);

const getAccessToken = () => get("feathers-jwt");
const isAuth = () => getAccessToken() !== null;

const getActiveBranch = () => get("activeBranch");
const setActiveBranch = id => set("activeBranch", id);
const removeActiveBranch = () => remove("activeBranch");

const getActiveCompany = () => get("activeCompany");
const setActiveCompany = id => set("activeCompany", id);
const removeActiveCompany = () => remove("activeCompany");

const getActiveUser = () => get("activeUser");
const setActiveUser = id => set("activeUser", id);
const removeActiveUser = () => remove("activeUser");

const getActiveMode = () => get("activeMode");
const setActiveMode = mode => set("activeMode", mode);
const removeActiveMode = () => remove("activeMode");

export {
  isAuth,
  getAccessToken,
  getActiveBranch,
  setActiveBranch,
  getActiveCompany,
  setActiveCompany,
  getActiveUser,
  setActiveUser,
  getActiveMode,
  setActiveMode,
  removeActiveBranch,
  removeActiveCompany,
  removeActiveUser,
  removeActiveMode
};
