// src/store/services/users.js
import feathersClient, {
  makeServicePlugin,
  BaseModel,
} from "../../feathers-client";

class Branch extends BaseModel {
  constructor(data, options) {
    super(data, options);
  }
  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = "Branch";
  // Define default properties here
  static instanceDefaults() {
    return {
      _id: "",
      active: true,
      name: "",
      phone: "",
      company: "",
      opened: false,
      automaticPrinting: false,
      printerColumnSize: 0,
      serviceFee: 0,
      location: {},
      locationTypes: [],
      paymentMethods: [],
      settingsApp: {},
      deliveryArea: {},
      takeAwayTime: 0,
      scheduling: {},
      takeAway: {},
      motoboyIntegrated: false,
      integrations: {},
      gateways: {
        pagseguro: {},
        iZettle: {},
      },
      copiesPrinting: 1,
      normalizePrinting: true,
      timeZone: "",
      printHtml: false,
      minPrice: 0,
      isDeliveryOnMinPrice: false,
      paymentMethodsDefault: {},
      isOnlinePaymentDisabled: false,
      staticImage: [],
    };
  }
  static setupInstance(data) {
    if (!data.integrations || !data.integrations.klavi) {
      data.integrations = {}
      data.integrations.klavi = {
        enabled: false,
        type: "",
        ageRestriction: false,
        doorSystem: false
      }
    }
    return data
  }
}
const servicePath = "manager/branches";
const servicePlugin = makeServicePlugin({
  Model: Branch,
  service: feathersClient.service(servicePath),
  servicePath,
});

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
});

export default servicePlugin;
