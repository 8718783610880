<template>
  <b-modal size="lg" :id="`pagseguro-modal-${branch._id}`" title="Pagseguro">
    <b-form @submit.prevent="handleSubmit">
      <b-form-group label="Email pagseguro">
        <div class="d-flex">
          <b-form-input
            v-model="psData.email"
            placeholder="fulano@de.tal"
            type="email"
            required
          />
          <b-button class="ml-1 btn-align-end" @click="getPsUrl">
            link
          </b-button>
        </div>
      </b-form-group>

      <b-form-group v-if="url">
        <div class="d-flex">
          <b-form-input v-model="url" type="text" disabled />

          <b-button class="ml-1 btn-align-end" @click="copyUrl">
            copiar
          </b-button>
        </div>
      </b-form-group>

      <b-form-group label="Quantidade de parcelas">
        <b-form-input
          v-model="psData.maxInstallments"
          placeholder="12"
          type="number"
          max="12"
          min="1"
        />
      </b-form-group>

      <b-form-group label="Ativo?">
        <custom-switch @toggled="toggle" id="active" :active="psData.active" />
      </b-form-group>

      <div class="custom-modal-footer">
        <b-button :disabled="disabled" type="submit" class="custom-btn">
          <div v-if="loading" class="spinner-border text-white"></div>
          <span v-else>Salvar</span>
        </b-button>
      </div>
    </b-form>
  </b-modal>
</template>

<script>
import CustomSwitch from "@/components/Utils/CustomSwitch";
import axios from "axios";

export default {
  name: "branchmodal",
  components: {
    CustomSwitch,
  },
  props: {
    branch: {
      type: Object,
      required: true,
    },
    ps: {
      type: Object,
      required: true,
    },
  },
  created() {
    if (this.ps.email) {
      this.psData = { ...this.psData, ...JSON.parse(JSON.stringify(this.ps)) };
    }
  },
  data() {
    return {
      psData: {
        active: false,
        email: "",
        maxInstallments: 1,
        fee: 0,
      },
      disabled: false,
      loading: false,
      url: null,
    };
  },
  methods: {
    toggle(data) {
      switch (data.id) {
        case "active":
          this.psData.active = data.checked;
          break;
      }
    },
    handleSubmit() {
      this.$emit("save-pagseguro", {
        branch: this.branch._id,
        ps: this.psData,
      });
    },
    getPsUrl() {
      if (this.psData.email) {
        axios
          .post("https://payments.weapp.com.br/create-merchant", {
            gateway: "pagseguro",
            email: this.psData.email,
          })
          .then(({ data }) => {
            this.psData.email = data.email;
            this.url = data.url;
          });
      }
    },
    copyUrl() {
      navigator.clipboard.writeText(this.url).then((t) => {
        console.log("url copiada");
      });
    },
  },
};
</script>

<style></style>
