<template>
  <footer class="footer border-top">
    <b-modal size="lg" id="new-company-modal" title="Nova company">
      <b-form @submit.prevent="handleSubmit">
        <b-form-group label="Informações da empresa">
          <div class="d-flex">
            <b-form-group label="Nome">
              <b-form-input
                v-model="companyData.name"
                placeholder="Restaurante fulano de tal"
                type="text"
                required
              />
            </b-form-group>

            <b-form-group label="Slug">
              <b-form-input
                v-model="companyData.slug"
                placeholder="restaurantefulanodetal"
                type="text"
                required
              />
            </b-form-group>

            <b-form-group label="Telefone">
              <b-form-input
                v-model="companyData.phone"
                placeholder="(11) 1111-1111"
                type="text"
                required
              />
            </b-form-group>
          </div>
        </b-form-group>

        <b-form-group label="Informações do proprietário">
          <div class="d-flex">
            <b-form-group label="Nome">
              <b-form-input
                v-model="companyData.ownerName"
                placeholder="Fulano de Tal"
                type="text"
                required
              />
            </b-form-group>

            <b-form-group label="Email">
              <b-form-input
                v-model="companyData.email"
                placeholder="fulanodetal@restaurante.com"
                type="text"
                required
              />
            </b-form-group>

            <b-form-group label="Senha">
              <b-form-input
                v-model="companyData.password"
                placeholder="12345678"
                type="text"
                required
              />
            </b-form-group>
          </div>
        </b-form-group>

        <b-form-group label="Informações da filial">
          <div class="d-flex">
            <b-form-group label="Nome">
              <b-form-input
                v-model="branchData.name"
                placeholder="12345678"
                type="text"
                required
              />
            </b-form-group>
            <b-form-group label="Telefone">
              <b-form-input
                v-model="branchData.phone"
                placeholder="(11) 1111-1111"
                type="text"
                required
              />
            </b-form-group>
          </div>
          <div class="d-flex">
            <b-form-group label="CEP">
              <b-form-input
                v-model="branchData.postalCode"
                placeholder="60000-000"
                type="text"
                required
              />
            </b-form-group>
            <b-form-group label="Rua">
              <b-form-input
                v-model="branchData.street"
                placeholder="Rua beltrano"
                type="text"
                required
              />
            </b-form-group>
          </div>
          <div class="d-flex">
            <b-form-group label="Número">
              <b-form-input
                v-model="branchData.number"
                placeholder="666"
                type="text"
                required
              />
            </b-form-group>
            <b-form-group label="Bairro">
              <b-form-input
                v-model="branchData.neighborhood"
                placeholder="Parque sicrano"
                type="text"
                required
              />
            </b-form-group>
          </div>
          <div class="d-flex">
            <b-form-group label="Cidade">
              <b-form-input
                v-model="branchData.city"
                placeholder="Cidade das flores"
                type="text"
                required
              />
            </b-form-group>
            <b-form-group label="Estado">
              <b-form-input
                v-model="branchData.state"
                placeholder="Estado das flores"
                type="text"
                required
              />
            </b-form-group>
          </div>
        </b-form-group>

        <div class="custom-modal-footer">
          <b-button :disabled="disabled" type="submit" class="custom-btn">
            <div v-if="loading" class="spinner-border text-white"></div>
            <span v-else>Salvar</span>
          </b-button>
        </div>
      </b-form>
    </b-modal>

    <b-input-group class="search">
      <b-form-input
        v-b-tooltip.focus
        title="Pressione enter para pesquisar"
        :value="search"
        @keyup="input($event)"
        placeholder="Busque por nome ou slug"
      />
      <b-input-group-append class="border">
        <b-button
          v-if="!search"
          variant="light"
          class="border-0"
          @click="emit()"
        >
          <i class="search-icon las la-search" />
        </b-button>
        <b-button v-else variant="danger" class="border-0" @click="clear()">
          <i class="search-icon las la-times" />
        </b-button>
      </b-input-group-append>
    </b-input-group>
    <p class="text-center m-0">
      Superdash ©2018 - {{ currentYear() }} <br />
      by Weapp
    </p>
    <b-button
      class="add"
      variant="success"
      @click="$bvModal.show('new-company-modal')"
    >
      Adicionar</b-button
    >
  </footer>
</template>

<script>
import moment from "moment";

export default {
  name: "the-footer",
  data() {
    return {
      search: "",
      disabled: false,
      loading: false,
      companyData: {},
      branchData: {},
    };
  },
  methods: {
    currentYear() {
      return moment().year();
    },
    emit() {
      this.$emit("search-text", this.search);
    },
    input(e) {
      this.search = e.target.value;

      if (e.keyCode === 13) this.emit();
    },
    clear() {
      this.search = "";

      this.emit();
    },
    handleSubmit() {
      const company = this.companyData;
      const branch = this.branchData;

      const data = {
        name: company.name,
        slug: company.slug,
        phone: company.phone,
        ownerData: {
          name: company.ownerName,
          email: company.email,
          password: company.password,
        },
        _branches: [
          {
            name: branch.name,
            phone: branch.phone,
            location: {
              address: {
                postalCode: branch.postalCode,
                street: branch.street,
                number: branch.number,
                neighborhood: branch.neighborhood,
                city: branch.city,
                state: branch.state,
              },
            },
          },
        ],
        type: "MERCHANT",
        transparentAccount: true,
        isCompanyActive: true,
      };

      this.$emit("new-company", data);
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  background: #f0f2f5;
  position: fixed;
  bottom: 0;
  z-index: 999;
  min-height: 70px;
  width: 100%;
  padding: 20px 30px;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  align-items: center;
}
</style>
