<template>
  <b-modal size="lg" :id="branch._id" :title="branch.name">
    <b-tabs>
      <b-tab title="Geral" active>
        <b-form @submit.prevent="handleSubmit">
          <b-form-group label="Nome">
            <b-form-input
              v-model="branchData.name"
              placeholder="Restaurante fulano de tal"
              type="text"
              required
            />
          </b-form-group>

          <b-form-group v-if="!branchData.mpAccount" label="Código Mercadopago">
            <b-form-input
              v-model="branchData.mercadopago"
              placeholder="TG-xxxxxxxxxxxxx-xxxxxx"
              type="text"
              disabled
            />
          </b-form-group>

          <b-form-group
            v-if="!hasBeeDelivery"
            label="CNPJ (beedelivery) - use apenas se o cliente já tem cadastro no bee"
          >
            <b-form-input
              v-model="branchData.beeCnpj"
              placeholder="00.000.000/0000-00"
              type="text"
            />
          </b-form-group>

          <b-form-group label="Ativa?">
            <custom-switch
              @toggled="toggle"
              id="active"
              :active="branchData.active"
            />
          </b-form-group>

          <b-form-group label="Aberta?">
            <custom-switch
              @toggled="toggle"
              id="open"
              :active="branchData.opened"
            />
          </b-form-group>

          <b-form-group label="Bee delivery ativo?" v-if="hasBeeDelivery">
            <custom-switch
              @toggled="toggle"
              id="beeActive"
              :active="branchData.integrations.beedelivery.enabled"
            />
          </b-form-group>

          <b-form-group
            v-if="branchData.mpAccount && branchData.mpAccount.refresh_token"
            label="Refresh token Mercadopago"
          >
            <b-form-input
              v-model="branchData.mpAccount.refresh_token"
              placeholder="TG-xxxxxxxxxxxxx-xxxxxx"
              type="text"
              disabled
            />
          </b-form-group>

          <b-button @click="$bvModal.show(`pagseguro-modal-${branchData._id}`)">
            Pagseguro
          </b-button>
          <b-button @click="getUrlAssociate(branchData._id)" class="ml-3">
            Mercadopago
          </b-button>
          <b-button
            @click="$bvModal.show(`IZettle-modal-${branchData._id}`)"
            class="ml-3"
          >
            iZettle
          </b-button>

          <PagseguroModal
            :branch="branchData"
            :ps="branchData.gateways.pagseguro"
            @save-pagseguro="savePagseguro"
          />
          <IZettleModal
            :branch="branchData"
            :iZettleData="branchData.gateways.iZettle"
            :loadingButton="loadingButton"
            @save-iZettle="saveiZettle"
          />
          <div>
            <span
              id="urlMpAssociate"
              style="max-width: 200px; text-align: center"
            ></span>
          </div>
          <div class="custom-modal-footer">
            <b-button
              :disabled="disabledBranch"
              type="submit"
              class="custom-btn"
            >
              <div v-if="loadingBranch" class="spinner-border text-white"></div>
              <span v-else>Salvar</span>
            </b-button>
          </div>
        </b-form>
      </b-tab>
      <b-tab title="Totem">
        <b-card class="mt-2">
          <template v-slot:header>
            <h5>Usuários do totem</h5>
          </template>
          <b-button
            style="float: right"
            class="custom-btn mb-2"
            @click="$bvModal.show(`TotemUser-modal-new`)"
          >
            Criar usuário
          </b-button>
          <TotemUserModal :totemUser="newTotemUser" />

          <b-table
            :items="filteredUsersTotem"
            :fields="fieldsTotemUsers"
            :key="filteredUsersTotem.length"
          >
            <template v-slot:cell(name)="data">
              <div style="max-width: 100px">
                {{ data.value }}
              </div>
            </template>
            <template v-slot:cell(active)="data">
              <custom-switch
                class="mt-3"
                :active="data.value"
                @toggled="
                  ({ checked }) => {
                    toggleActiveTotemUser(data.item._id, checked);
                    data.value = checked;
                  }
                "
              />
            </template>
            <template v-slot:cell(action)="data">
              <b-list-group horizontal>
                <i
                  class="la la-pen icon mr-3"
                  @click="$bvModal.show(`TotemUser-modal-${data.item._id}`)"
                ></i>
                <TotemUserModal :totemUser="data.item" />
                <i
                  class="la la-trash-alt icon mr-3"
                  @click="$bvModal.show(`DeleteTotemUser-${data.item._id}`)"
                ></i>
                <b-modal
                  size="lg"
                  :id="`DeleteTotemUser-${data.item._id}`"
                  title="Confirmar exclusão"
                >
                  Deseja realmente excluir o usuario
                  <strong> {{ data.item.email }} </strong> ?
                  <div class="custom-modal-footer">
                    <b-button
                      style="float: right"
                      variant="danger"
                      @click="removeTotemUser(data.item._id)"
                    >
                      <div
                        v-if="loadingRemoveTotemUserButton"
                        class="spinner-border spinner-border-sm text-white"
                      ></div>
                      <span v-else>Excluir</span>
                    </b-button>
                  </div>
                </b-modal>
              </b-list-group>
            </template>
            <template v-slot:cell(email)="data">
              <div style="max-width: 150px">
                <span>
                  {{ data.value }}
                </span>
              </div>
            </template>
          </b-table>
        </b-card>
      </b-tab>
      <b-tab title="Loja autônoma" v-if="branchData.integrations.klavi.enabled">
        <b-form @submit.prevent="handleSubmit('autonomousStore')">
          <b-form-group label="Tipo" class="mt-3">
            <b-form-select
              required
              title="Tipo de item"
              v-model="branchData.integrations.klavi.type"
              :options="klaviTypeOptions"
              class="w-50"
            ></b-form-select>
          </b-form-group>
          <b-form-group label="Integração ativa">
            <custom-switch
              @toggled="
                ({ checked }) => {
                  branchData.integrations.klavi.enabled = checked;
                }
              "
              id="activeIntegration"
              :active="branchData.integrations.klavi.enabled"
            />
          </b-form-group>
          <b-form-group label="Restrição de idade">
            <custom-switch
              @toggled="
                ({ checked }) => {
                  branchData.integrations.klavi.ageRestriction = checked;
                }
              "
              id="ageRestriction"
              :active="branchData.integrations.klavi.ageRestriction"
            />
          </b-form-group>
          <b-form-group label="Sistema de abertura/fechamento de porta">
            <custom-switch
              @toggled="
                ({ checked }) => {
                  branchData.integrations.klavi.doorSystem = checked;
                }
              "
              id="doorSystem"
              :active="branchData.integrations.klavi.doorSystem"
            />
          </b-form-group>
          <b-form-group label="Leitor de código de barras">
            <custom-switch
              @toggled="
                ({ checked }) => {
                  branchData.barcodeReader = checked;
                }
              "
              id="barcodeReader"
              :active="branchData.barcodeReader"
            />
          </b-form-group>
          <div class="custom-modal-footer">
            <b-button
              :disabled="disabledBranch"
              type="submit"
              class="custom-btn"
            >
              <div v-if="loadingBranch" class="spinner-border text-white"></div>
              <span v-else>Salvar</span>
            </b-button>
          </div>
        </b-form>
      </b-tab>
    </b-tabs>
  </b-modal>
</template>

<script>
import CustomSwitch from "@/components/Utils/CustomSwitch";
import PagseguroModal from "@/components/PagseguroModal";
import IZettleModal from "@/components/IZettleModal";
import TotemUserModal from "@/components/TotemUserModal";
import { getAccessToken } from "@/helpers/storage";
import Locations from "@/network/locations";
import { get, patch, remove } from "@/network/rest";
import { syncAll } from "@/helpers/queries";
import { showToast } from "../../helpers/toast";

export default {
  name: "branchmodal",
  components: {
    CustomSwitch,
    PagseguroModal,
    IZettleModal,
    TotemUserModal,
  },
  props: {
    branch: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    loadingBranch: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
    disabledBranch: {
      type: Boolean,
      required: true,
    },
    loadingButton: {
      type: Boolean,
      required: true,
    },
    companyId: {
      type: String,
      required: true,
    },
  },
  computed: {
    accessToken: () => getAccessToken(),
    hasBeeDelivery() {
      return (
        this.branch.integrations &&
        this.branch.integrations.beedelivery &&
        this.branch.integrations.beedelivery.linked
      );
    },
    usersTotem() {
      return syncAll("TotemUsers").data;
    },
    filteredUsersTotem() {
      return this.usersTotem.filter(
        (user) =>
          user.customerOf === this.companyId && user.branch === this.branch._id
      );
    },
  },
  created() {
    this.branchData = this.branch;
    if (!this.branchData.gateways) {
      this.branchData.gateways = {
        pagseguro: {},
        iZettle: {},
      };
    }

    if (!this.branchData.gateways.iZettle) {
      this.branchData.gateways.iZettle = {};
    }
    if (!this.branchData.integrations) {
      this.branchData.integrations = {};
    }

    if (!this.branchData.integrations.beedelivery) {
      this.branchData.integrations.beedelivery = {
        linked: false,
        enabled: false,
      };
    }
  },
  data() {
    return {
      branchData: {},
      loadingRemoveTotemUserButton: false,
      fieldsTotemUsers: [
        { key: "name", label: "Nome", sortable: true },
        {
          key: "maxConcurrentLogins",
          label: "logins permitidos",
          sortable: true,
        },
        { key: "email", label: "Email", sortable: true },
        { key: "active", label: "Ativo", sortable: true },
        { key: "action", label: "Ações" },
      ],
      newTotemUser: {
        _id: "new",
        active: true,
        iatVerify: [],
        customerOf: this.companyId,
        branch: this.branch._id,
      },
      klaviTypeOptions: [
        {
          value: "",
          text: "Selecione uma opção",
          disabled: true,
        },
        {
          value: "porta",
          text: "Porta",
        },
        { value: "container", text: "Container" },
        { value: "freezer", text: "Freezer" },
      ],
    };
  },
  methods: {
    toggle(data) {
      switch (data.id) {
        case "active":
          this.branchData.active = data.checked;
          break;
        case "open":
          this.branchData.opened = data.checked;
          break;
        case "beeActive":
          this.branchData.integrations.beedelivery.enabled = data.checked;
          this.branchData.beeActive = data.checked;
          break;
      }
    },
    async toggleActiveTotemUser(id, status) {
      try {
        await patch(getAccessToken(), `${Locations.TotemUsers}/${id}`, {
          active: status,
        });
        showToast(
          this,
          `Usuário ${status ? "Ativado" : "Desativado"} com sucesso`,
          "success"
        );
      } catch (e) {
        console.log(e);
        showToast(
          this,
          `Erro ao ${status ? "ativar" : "desativar"} usuário`,
          "danger"
        );
      }
    },
    async removeTotemUser(id) {
      this.loadingRemoveTotemUserButton = true;
      try {
        await remove(getAccessToken(), `${Locations.TotemUsers}/${id}`);
        showToast(this, "Usuário excluido com sucesso", "success");
        this.loadingRemoveTotemUserButton = false;
        this.$bvModal.hide(`DeleteTotemUser-${id}`);
      } catch (e) {
        showToast(this, "Erro ao excluir usuário", "danger");
        this.loadingRemoveTotemUserButton = false;
        console.log(e);
      }
    },
    handleSubmit(tab) {
      let data;

      if (tab === "autonomousStore") {
        const { ageRestriction, doorSystem, type, enabled } =
          this.branchData.integrations.klavi;

        const { _id: id, barcodeReader } = this.branchData;

        data = {
          barcodeReader,
          "integrations.klavi.enabled": enabled,
          "integrations.klavi.ageRestriction": ageRestriction,
          "integrations.klavi.doorSystem": doorSystem,
          "integrations.klavi.type": type,
          id,
        };
      } else {
        const { name, active, opened, beeCnpj, integrations } = this.branchData;

        data = {
          mercadopago: this.branchData.mercadopago,
          name,
          active,
          opened,
          id: this.branchData._id,
          beeCnpj,
          integrations,
        };
      }
      this.$emit("save-branch", data);
    },
    savePagseguro(e) {
      this.$emit("save-pagseguro", e);
    },
    saveiZettle(data) {
      this.$emit("save-iZettle", data);
    },
    async getUrlAssociate(id) {
      const res = await get(
        this.accessToken,
        Locations.associateMpBranch + `/${id}`
      );
      navigator.clipboard.writeText(res.data.url);
    },
  },
};
</script>

<style lang="scss" scoped>
.icon {
  color: $accent-color;
  font-size: 2em;
}
.icon:hover {
  cursor: pointer;
}
</style>
