<template>
  <b-modal size="lg" :id="companyData._id" :title="companyData.name">
    <b-form @submit.prevent="handleSubmit()">
      <div class="d-flex">
        <b-form-group label="Nome" class="mr-1">
          <b-form-input
            v-model="companyData.name"
            placeholder="Restaurante fulano de tal"
            type="text"
            required
          />
        </b-form-group>

        <b-form-group label="Slug" class="mr-1">
          <b-form-input
            v-model="companyData.slug"
            placeholder="restaurantefulanodetal"
            type="text"
            required
          />
        </b-form-group>

        <b-form-group label="Versão mínima weapp" class="mr-1">
          <b-form-input
            v-model="companyData.minAppVersion.mobile"
            placeholder="1.0.0"
            type="text"
            required
          />
        </b-form-group>
      </div>

      <b-form-group label="Fidelidade desativada?">
        <custom-switch
          @toggled="toggle"
          id="loyalty"
          :active="companyData.loyalty.points.disabled"
        />
      </b-form-group>

      <b-form-group label="Modo drive-thru ativado?">
        <custom-switch
          @toggled="toggle"
          id="driveThru"
          :active="companyData.driveThruEnabled"
        />
      </b-form-group>

      <b-form-group label="Modo pré-pago ativado?">
        <custom-switch
          @toggled="toggle"
          id="prepaid"
          :active="companyData.prepaidEnabled"
        />
      </b-form-group>

      <b-form-group label="Link de compartilhamento do app">
        <b-form-input
          v-model="companyData.appUrl"
          placeholder="http://onelink.to/s3cjfm"
          type="text"
          required
        />
      </b-form-group>

      <b-form-group label="Tipos de consumo">
        <b-form-checkbox-group
          v-model="companyData.locationTypes"
          :options="options"
        />
      </b-form-group>

      <b-form-group label="Moeda">
        <b-form-radio-group
          v-model="companyData.settings.currency"
          :options="currency"
        />
      </b-form-group>

      <b-card>
        <a href="" target="_blank" @click.prevent="customize = !customize">
          Personalizar
          <i class="las la-angle-up" v-if="customize" />
          <i class="las la-angle-down" v-else />
        </a>

        <b-collapse v-show="customize" :visible="customize">
          <!-- funcionalidades -->

          <b-form-group label="Funcionalidades">
            <b-form-group label="Login com facebook desativado?">
              <custom-switch
                @toggled="toggle"
                id="fb"
                :active="companyData.isFacebookLoginInactive"
              />
            </b-form-group>

            <b-form-group
              label="Desativar menu de categorias na página do cardápio?"
            >
              <custom-switch
                @toggled="toggle"
                id="categories"
                :active="companyData.hideCategoriesMenu"
              />
            </b-form-group>

            <b-form-group
              label="Permitir que o app disponha ao usuário uma tela com mapa e informações de todas as filiais?"
            >
              <custom-switch
                @toggled="toggle"
                id="map"
                :active="companyData.allowAllBranchesView"
              />
            </b-form-group>
          </b-form-group>

          <!-- cores -->

          <b-form-group label="Cores" class="teste">
            <div class="double-grid">
              <b-form-group label="Primária" class="mr-1">
                <chrome-picker
                  v-model="companyData.settings.primaryColor"
                  :disableFields="true"
                  :disableAlpha="true"
                  @input="teste($event, true)"
                  class="mb-1"
                />
                <b-form-input
                  v-model="companyData.settings.primaryColor"
                  :placeholder="baseColor"
                  type="text"
                  required
                />
              </b-form-group>

              <b-form-group label="Secundária" class="mr-1">
                <chrome-picker
                  v-model="companyData.settings.secundaryColor"
                  :disableFields="true"
                  :disableAlpha="true"
                  @input="teste($event, false)"
                  class="mb-1"
                />
                <b-form-input
                  v-model="companyData.settings.secundaryColor"
                  :placeholder="baseColor"
                  type="text"
                  required
                />
              </b-form-group>
            </div>
          </b-form-group>

          <!-- textos -->

          <b-form-group label="Textos">
            <div class="d-flex">
              <b-form-group label="Delivery" class="mr-1">
                <b-form-input
                  v-model="companyData.settings.deliveryText"
                  :placeholder="defaultTexts.delivery"
                  type="text"
                  required
                />
              </b-form-group>

              <b-form-group label="Retirada" class="mr-1">
                <b-form-input
                  v-model="companyData.settings.toGoText"
                  :placeholder="defaultTexts.store"
                  type="text"
                  required
                />
              </b-form-group>

              <b-form-group label="Local" class="mr-1">
                <b-form-input
                  v-model="companyData.settings.onStoreText"
                  :placeholder="defaultTexts.local"
                  type="text"
                  required
                />
              </b-form-group>

              <b-form-group label="Lojas" class="mr-1">
                <b-form-input
                  v-model="companyData.settings.ourStoresText"
                  :placeholder="defaultTexts.stores"
                  type="text"
                  required
                />
              </b-form-group>

              <b-form-group label="Drive-thru" class="mr-1">
                <b-form-input
                  v-model="companyData.settings.driveThruText"
                  :placeholder="defaultTexts.driveThru"
                  type="text"
                />
              </b-form-group>

              <b-form-group
                label="Informação drive thru (placa/posição/vaga/etc)"
                class="mr-1"
              >
                <b-form-input
                  v-model="companyData.settings.driveThruInfo"
                  :placeholder="defaultTexts.driveThruInfo"
                  type="text"
                />
              </b-form-group>
            </div>
          </b-form-group>
        </b-collapse>
      </b-card>

      <b-form-group label="Branches" class="mt-5 mb-3">
        <div v-if="ok">
          <div>
            <b-table hover :items="branches" :fields="fieldsBranch">
              <template v-slot:cell(location)="data">
              {{ data.value.address ? data.value.address.street+","
                +data.value.address.number +","
                +data.value.address.neighborhood +","
                +data.value.address.city +"-"+data.value.address.state+"("
                +data.value.address.postalCode
                +")" : " "
              }}
                
              </template>
              <template v-slot:cell(opened)="data">
                {{ data.value ? "Sim" : "Não" }}
              </template>
              <template v-slot:cell(action)="data">
                <div class="side">
                  <i
                    class="la la-pen icon mr-4 pointer"
                    @click="$bvModal.show(data.item._id)"
                  >
                    <BranchModal
                      :branch="data.item"
                      :disabled="branchDisabled"
                      :disabledBranch="disabledBranch"
                      :loading="branchLoading"
                      :loadingBranch="loadingBranch"
                      :loadingButton="loadingButton"
                      :companyId="company._id"
                      @save-branch="handleBranchSubmit"
                      @save-pagseguro="savePagseguro"
                      @save-iZettle="saveiZettle"
                  /></i>
                  <i
                    class="la la-trash-alt icon"
                    @click="$bvModal.show(`remov-${data.item._id}`)"
                  ></i>
                  <b-modal
                    :id="`remov-${data.item._id}`"
                    title="Confirmar ação"
                  >
                    <p>
                      Deseja mesmo excluir? <strong>{{ data.item.name }}</strong
                      >?
                    </p>
                    <div class="custom-modal-footer mt-4">
                      <b-button type="submit" @click="deleteBranch(data.item._id)" variant="danger">
                        <div class="text-white">Excluir</div>
                      </b-button>
                    </div>
                  </b-modal>
                </div>
              </template>
            </b-table>
            <b-button  @click="$bvModal.show(`add-1`)" class="custom-btn">Adicionar filial</b-button>
            <b-modal size="lg" :id="`add-1`" title="Adicionar filial">
                <b-form-group>
                  <div class="d-flex">
                    <b-form-group label="Nome*">
                      <b-form-input
                        v-model="branchNewData.name"
                        placeholder="Filial fulano de tal"
                        type="text"
                        required
                      />
                    </b-form-group>
                    <b-form-group label="Telefone*">
                      <b-form-input
                        v-model="branchNewData.phone"
                        placeholder="(11) 1111-1111"
                        type="text"
                        required
                      />
                    </b-form-group>
                  </div>
                  <div class="d-flex">
                    <b-form-group label="CEP">
                      <b-form-input
                        v-model="branchNewData.postalCode"
                        placeholder="60000-000"
                        type="text"
                        required
                      />
                    </b-form-group>
                    <b-form-group label="Rua">
                      <b-form-input
                        v-model="branchNewData.street"
                        placeholder="Rua beltrano"
                        type="text"
                        required
                      />
                    </b-form-group>
                  </div>
                  <div class="d-flex">
                    <b-form-group label="Número">
                      <b-form-input
                        v-model="branchNewData.number"
                        placeholder="666"
                        type="text"
                        required
                      />
                    </b-form-group>
                    <b-form-group label="Bairro">
                      <b-form-input
                        v-model="branchNewData.neighborhood"
                        placeholder="Parque sicrano"
                        type="text"
                        required
                      />
                    </b-form-group>
                  </div>
                  <div class="d-flex">
                    <b-form-group label="Cidade">
                      <b-form-input
                        v-model="branchNewData.city"
                        placeholder="Cidade das flores"
                        type="text"
                        required
                      />
                    </b-form-group>
                    <b-form-group label="Estado">
                      <b-form-input
                        v-model="branchNewData.state"
                        placeholder="Ex: SP"
                        type="text"
                        required
                      />
                    </b-form-group>
                  </div>
                </b-form-group>
                <div class="custom-modal-footer">
                  <b-button @click="createBranch()" class="custom-btn">
                    <div v-if="loadingNewBranch" class="spinner-border text-white"></div>
                    <span v-else>Salvar</span>
                  </b-button>
                </div>
            </b-modal>
          </div>
        </div>
        <div v-else>Nenhum branch carregado ainda</div>
      </b-form-group>

      <div class="custom-modal-footer">
        <b-button :disabled="disabled" type="submit" class="custom-btn">
          <div v-if="loading" class="spinner-border text-white"></div>
          <span v-else>Salvar</span>
        </b-button>
      </div>
    </b-form>
  </b-modal>
</template>

<script>
import { genericRequest } from "@/helpers/queries";
import { getAccessToken } from "@/helpers/storage";
import Locations from "@/network/locations";
import BranchModal from "@/components/BranchModal";
import { Chrome } from "vue-color";
import CustomSwitch from "@/components/Utils/CustomSwitch";
import { toast } from "@/helpers/toast";

import { post , remove } from "@/network/rest";

export default {
  name: "category-modal",
  components: {
    BranchModal,
    ChromePicker: Chrome,
    CustomSwitch,
  },
  props: {
    company: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    loadingBranch: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
    disabledBranch: {
      type: Boolean,
      required: true,
    },
    loadingButton: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    accessToken: () => getAccessToken(),
    feathers() {
      return this.$FeathersVuex.api;
    },
    branches() {
      return this.feathers.Branch.findInStore({
        query: { company: this.company._id },
      }).data;
    },
  },
  async created() {
    this.companyData = JSON.parse(JSON.stringify(this.company));

    const Branch = this.feathers.Branch;
    const accessToken = this.accessToken;
    const companyId = this.company._id;
    const branches = Locations.branches;

    /* versão minima do app */

    if (!this.companyData.minAppVersion) {
      this.companyData.minAppVersion = { mobile: "1.0.0" };
    }

    /* configurações */

    if (
      typeof this.companyData.settings !== "object" ||
      !this.companyData.settings
    ) {
      this.companyData.settings = {};
    }

    // cores

    if (!this.companyData.settings.primaryColor) {
      this.companyData.settings.primaryColor = this.baseColor;
    }

    if (!this.companyData.settings.secundaryColor) {
      this.companyData.settings.secundaryColor = this.baseColor;
    }

    /* pegando branches */

    if (!this.ok) {
      await genericRequest(Branch, accessToken, companyId, branches);

      this.ok = true;
    }
  },
  data() {
    return {
      fieldsBranch: [
        {
          key: "name",
          label: "Nome",
        },
        {
          key: "location",
          label: "Endereço",
        },
        {
          key: "opened",
          label: "Aberto",
        },
        {
          key: "action",
          label: "Ações",
        },
      ],
      categoryData: {
        currentCategory: null,
        locationTypes: [],
        name: "",
        description: "",
        img: "",
      },
      loadingNewBranch: false,
      companyData: {},
      branchNewData: {},
      branchData: {},
      image: null,
      ok: false,
      branchLoading: false,
      branchDisabled: false,
      baseColor: "#FFF",
      customize: false,
      defaultTexts: {
        delivery: "Delivery",
        store: "Retirada na loja",
        local: "Consumo no local",
        stores: "Onde estamos",
        driveThru: "Drive-thru",
        driveThruInfo: "Placa/posição",
      },
      options: [
        { text: "Delivery", value: "1" },
        { text: "Retirada", value: "2" },
        { text: "Local", value: "4" },
        { text: "Totem", value: "8" },
      ],
      currency: [
        { text: "Real", value: "BRL" },
        { text: "Dólar", value: "USD" },
        { text: "Euro", value: "EUR" },
      ],
    };
  },
  methods: {
    teste(e, primary) {
      if (primary) {
        this.companyData.settings.primaryColor = e.hex;
      } else {
        this.companyData.settings.secundaryColor = e.hex;
      }
    },
    handleSubmit() {
      this.$emit("save-company", this.companyData);
    },
    handleBranchSubmit(data) {
      this.$emit("handle-branch-submit", data);
    },
    savePagseguro(data) {
      this.$emit("save-pagseguro", data);
    },
    saveiZettle(data) {
      this.$emit("save-iZettle", data);
    },
    async createBranch(){
      this.loadingNewBranch = true;
      
      try{
        if(!this.branchNewData?.name || !this.branchNewData?.phone){
          toast(
            this,
            "Ocorreu um erro",
            `O campo ${!(this.branchNewData?.name) ? "Nome" : "Telefone"} não pode estar vazio`,
            3000,
            "danger"
          );
        }
        console.log(this.branchNewData)
        await post(this.accessToken, Locations.branches, {
          ...this.branchNewData,
          company:this.companyData._id,
          location: {
            type: "Point",
            coordinates: [0,0],            
            address: this.branchNewData?.street && this.branchNewData?.postalCode ?{
              postalCode: this.branchNewData.postalCode,
              street:this.branchNewData.street,
              number:this.branchNewData.number,
              neighborhood:this.branchNewData.neighborhood,
              city:this.branchNewData.city,
              state:this.branchNewData.state,
            }
            : null
          }
        })

        toast(
            this,
            `Filial ${this.branchNewData?.name.toUpperCase()} foi criada com sucesso`,
            `Acesse o dashboard para ter acesso total a configuração da filial.`,
            4000,
            "success"
          );

        this.$bvModal.hide(`add-1`)

      }catch(err){
          console.log(err.response)
          toast(
            this,
            "Ocorreu um erro",
            `${err.response.data.message}`,
            3000,
            "danger"
          );
      }finally {
        this.loadingNewBranch = false;
      }
    },
    async deleteBranch(branchId){
      try{

        await remove(this.accessToken, Locations.branches+"/"+branchId);

        toast(
            this,
            `Filial removida com sucesso`,
            ``,
            3000,
            "success"
          );
      }catch(err){
        console.log(err)
        toast(
            this,
            "Ocorreu um erro",
            `${err.response.data.message}`,
            3000,
            "danger"
          );
      }finally{
        $bvModal.hide(`remov-${branchId}`)
      }
    },
    toggle(data) {
      switch (data.id) {
        case "fb":
          this.companyData.isFacebookLoginInactive = data.checked;
          break;
        case "categories":
          this.companyData.hideCategoriesMenu = data.checked;
          break;
        case "map":
          this.companyData.allowAllBranchesView = data.checked;
          break;
        case "loyalty":
          this.companyData.loyalty.points.disabled = data.checked;
          break;
        case "driveThru":
          this.companyData.driveThruEnabled = data.checked;
          break;
        case "prepaid":
          this.companyData.prepaidEnabled = data.checked;
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./style.scss";
.vc-chrome {
  margin: auto;
}

.double-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: center;
}

.branches-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.iconsSM {
  color: $accent-color;
}
.iconsSM:hover {
  cursor: pointer;
  transform: scale(1.1, 1.1);
}
.iconsSM:active {
  transform: scale(0.9, 0.9);
}
.pointer {
  cursor: pointer;
}
</style>
