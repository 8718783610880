<template>
  <label class="app-switch mr-3">
    <span>{{ label }}</span>
    <input type="checkbox" :checked="checked" @change="handleChange()" />
    <span class="slider"></span>
  </label>
</template>

<script>
export default {
  name: "custom-switch",
  props: ["label", "id", "active"],
  data() {
    return {
      checked: true
    };
  },
  methods: {
    handleChange() {
      this.checked = !this.checked;
      this.$emit("toggled", { id: this.id, checked: this.checked });
    }
  },
  created() {
    this.checked = this.active;
  },
  watch: {
    active() {
      this.checked = this.active;
    }
  }
};
</script>

<style lang="scss" scoped>
.app-switch {
  position: relative;
  display: inline-block;
  padding-left: 50px;
  height: 16px;
  span:not(.slider) {
    position: relative;
    top: -2px;
    cursor: pointer;
    font-weight: 300;
  }
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    width: 42px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 34px;
    background-color: $gray-300;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  .slider:before {
    position: absolute;
    content: "";
    height: 24px;
    width: 24px;
    left: -1px;
    bottom: -4px;
    background-color: #fff;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
      0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  }
  input:checked + .slider {
    background-color: $accent-color;
  }
  input:focus + .slider {
    box-shadow: 0 0 1px $accent-color;
  }
  input:checked + .slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
  }
}
</style>
