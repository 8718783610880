// src/store/services/users.js
import feathersClient, {
  makeServicePlugin,
  BaseModel
} from "../../feathers-client";

// propriedades que só existem aqui no dash
const customProperties = {
  manualSort: false,
  modified: null
};

class ProductCategory extends BaseModel {
  constructor(data, options) {
    super(data, options);
  }
  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = "ProductCategory";
  // Define default properties here
  static instanceDefaults() {
    return {
      _id: "",
      branch: "",
      company: "",
      description: "",
      disabled: false,
      locationTypes: [],
      name: "",
      products: [],
      staticImage: {},
      ...customProperties
    };
  }
}
const servicePath = "manager/product-categories";
const servicePlugin = makeServicePlugin({
  Model: ProductCategory,
  service: feathersClient.service(servicePath),
  servicePath
});

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
});

export default servicePlugin;
