<template>
  <b-modal size="lg" :id="`IZettle-modal-${branch._id}`" title="iZettle">
    <b-form @submit.prevent="handleSubmit">
      <b-list-group horizontal>
        <b-button class="custom-btn mb-3" size="sm" @click="getLinkAuth">
          Copiar link de autorização
        </b-button>
        <ToolTip
          id="linkToolTip"
          message="O link é copiado para o Ctrl + C ao clicar no botão ao lado. Envie esse link para o estabelecimento para que o mesmo crie uma chave de autorização para o Weapp. Após a criação da chave pelo estabelecimento, solicite a mesma e a informe no campo 'token novo'."
        />
      </b-list-group>
      <b-form-group label="Token atual">
        <b-form-input
          v-model="iZettleData.token"
          placeholder="jwt AES encoded"
          type="text"
          disabled
          id="iZettleTotem"
        />
      </b-form-group>
      <b-form-group label="Token novo">
        <div>
          <b-list-group horizontal>
            <b-form-input
              v-model="newToken"
              placeholder="jwt token"
              type="text"
            />
            <ToolTip
              id="tokenToolTip"
              message="Caso deseje alterar o token, cole o mesmo aqui dentro e clique no botão salvar. O token final que ficará salvo será diferente do informado"
            />
          </b-list-group>
        </div>
      </b-form-group>

      <b-form-group v-if="url">
        <div class="d-flex">
          <b-form-input v-model="url" type="text" disabled />

          <b-button class="ml-1 btn-align-end" @click="copyUrl">
            copiar
          </b-button>
        </div>
      </b-form-group>

      <b-form-group :label="iZettleData.active ? 'Ativado' : 'Desativado'">
        <custom-switch
          @toggled="toggle"
          id="active"
          :active="iZettleData.active"
        />
      </b-form-group>

      <div class="custom-modal-footer">
        <b-button :disabled="disabled" type="submit" class="custom-btn">
          <div
            v-if="loadingButton"
            class="spinner-border spinner-border-sm text-white"
          ></div>
          <span v-else>Salvar</span>
        </b-button>
      </div>
    </b-form>
  </b-modal>
</template>

<script>
import CustomSwitch from "@/components/Utils/CustomSwitch";
import ToolTip from "@/components/Utils/ToolTip";
import { showToast } from "@/helpers/toast";

export default {
  name: "IZettleModal",
  components: {
    CustomSwitch,
    ToolTip,
  },
  props: {
    branch: {
      type: Object,
      required: true,
    },
    iZettleData: {
      type: Object,
      required: true,
    },
    loadingButton: {
      type: Boolean,
      required: true,
    },
  },
  watch: {
    "iZettleData.token"() {
      document.getElementById("iZettleTotem").style.borderColor = "green";
      document.querySelector("#iZettleTotem").style.backgroundColor = "white";
      setTimeout(() => {
        document.getElementById("iZettleTotem").style.borderColor = "";
        document.querySelector("#iZettleTotem").style.backgroundColor = "";
      }, 500);
    },
  },

  data() {
    return {
      iZettle: {
        active: false,
        jwt: "",
      },
      newToken: "",
      disabled: false,
      url: null,
    };
  },
  methods: {
    getLinkAuth() {
      navigator.clipboard.writeText(
        "https://my.izettle.com/apps/api-keys?name=Supermenu%20Api&scopes=READ:PRODUCT%20WRITE:PRODUCT%20READ:FINANCE%20WRITE:FINANCE%20READ:PURCHASE%20WRITE:PURCHASE%20READ:USERINFO%20WRITE:USERINFO%20READ:CUSTOMER%20WRITE:CUSTOMER%20READ:ONLINEPAYMENT%20WRITE:ONLINEPAYMENT%20READ:PAYMENT%20WRITE:PAYMENT%20WRITE:REFUND%20WRITE:REFUND2"
      );
      showToast(this, `Link copiado com sucesso`, "success");
    },
    toggle(data) {
      switch (data.id) {
        case "active":
          this.iZettleData.active = data.checked;
          break;
      }
    },
    async handleSubmit() {
      const newiZettleGateway = {
        branch: this.branch._id,
        active: this.iZettleData.active,
      };

      if (this.newToken) {
        newiZettleGateway.newToken = this.newToken;
      } else {
        newiZettleGateway.encodedToken = this.iZettleData.token;
      }
      this.$emit("save-iZettle", newiZettleGateway);
      this.newToken = "";
    },
  },
};
</script>

<style></style>
