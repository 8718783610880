import { get, getAll } from "@/network/rest";
import Locations from "@/network/locations";
import {
  getActiveBranch,
  getActiveCompany,
  getAccessToken,
} from "@/helpers/storage";
import { models } from "../feathers-client";

const log = (msg) => console.log(`${msg} synced`);

const findSyncPaginated = async (
  modelName,
  accessToken,
  location,
  query = {}
) => {
  const model = models.api[modelName];
  const allDocuments = [];
  const paginate = Number(process.env.VUE_APP_PAGINATE_SEARCH || 200);
  const querySchema = new URLSearchParams(query).toString();
  let count = 0;
  const loopGet = async () => {
    const { data } = await get(
      accessToken,
      `${location}?$limit=${paginate}&$skip=${count * paginate}&${querySchema}`
    );
    data.data && typeof data.data !== "undefined"
      ? data.data.forEach((user) => allDocuments.push(user))
      : data.forEach((user) => allDocuments.push(user));
    count += 1;
    const times = Math.ceil(data.total / paginate);

    if (count >= times) {
      allDocuments.map((document) => {
        new model(document);
      });
      log(location);

      return allDocuments;
    } else {
      await loopGet();
    }
  };
  return await loopGet();
};

// get all companies
const getCompanies = async (model, accessToken, where) => {
  const allCompanies = [];
  const { data } = await get(accessToken, where);

  const total = data.total;
  const skip = 50;
  const times = Math.ceil(total / skip);

  for (let i = 0; i < times; i++) {
    const { data } = await get(
      accessToken,
      `${where}?$limit=${skip}&$skip=${i * skip}`
    );
    data.data.forEach((company) => allCompanies.push(company));
  }

  allCompanies.forEach((company) => new model(company));
};

const getCompany = async (model, accessToken, where, companyId) => {
  const companyRequest = await get(accessToken, `${where}/${companyId}`);
  const company = new model(companyRequest.data);

  log(company.slug);
};

const _getUser = async (model, accessToken, where, userId) => {
  const userRequest = await get(accessToken, `${where}/${userId}`);
  const user = new model(userRequest.data);
};

const getUser = async (feathers, accessToken, userId) => {
  const { User } = feathers;
  const { customers } = Locations;

  await _getUser(User, accessToken, customers, userId);

  return true;
};

const getComplementGroup = async (feathers, accessToken, groupId) => {
  const { ComplementGroup } = feathers;
  const { complementsGroups } = Locations;

  const complementRequest = await get(
    accessToken,
    `${complementsGroups}/${groupId}`
  );
  const complement = new ComplementGroup(complementRequest.data);

  return true;
};

const genericRequest = async (
  model,
  accessToken,
  companyId,
  where,
  branchId,
  minTime
) => {
  const all = await getAll(accessToken, companyId, where, branchId, minTime);

  if (all === null) throw `erro ao sincronizar ${where}`;

  all.forEach((one) => new model(one));

  log(where);

  return true;
};

const populateCompany = async (feathers, accessToken, companyId) => {
  const { Company, Branch } = feathers;
  const { companies, branches } = Locations;

  await getCompany(Company, accessToken, companies, companyId);
  await genericRequest(Branch, accessToken, companyId, branches);

  log("company all");

  return true;
};

const populateUsers = async (feathers, accessToken, companyId, branchId) => {
  const { User } = feathers;
  const { users } = Locations;

  await genericRequest(User, accessToken, companyId, users, branchId);

  log("users");

  return true;
};

const populateOrders = async (
  feathers,
  accessToken,
  companyId,
  branchId,
  hours
) => {
  const { Order } = feathers;
  const { orders } = Locations;
  const minTime = hours ? hours * 60 * 60 * 1000 : 12 * 60 * 60 * 1000;

  await genericRequest(
    Order,
    accessToken,
    companyId,
    orders,
    branchId,
    new Date().getTime() - minTime
  );

  log("orders");

  return true;
};

const populateBranch = async (feathers, accessToken, companyId, branchId) => {
  const { ProductCategory, ComplementGroup, Period, Product } = feathers;
  const {
    productsCategories,
    complementsGroups,
    periods,
    products,
  } = Locations;
  await genericRequest(
    ProductCategory,
    accessToken,
    companyId,
    productsCategories,
    branchId
  );

  await genericRequest(
    ComplementGroup,
    accessToken,
    companyId,
    complementsGroups,
    branchId
  );

  await genericRequest(Period, accessToken, companyId, periods, branchId);

  await genericRequest(Product, accessToken, companyId, products, branchId);

  log("initial state all");

  return true;
};

const syncAll = (model, query = {}) => {
  const documents = models.api[model].findInStore(query);
  if (documents.total == 0)
    findSyncPaginated(model, getAccessToken(), Locations[model], query);
  return documents;
};

const query = () => ({
  company: getActiveCompany(),
  branch: getActiveBranch(),
});

export {
  findSyncPaginated,
  populateCompany,
  populateBranch,
  populateOrders,
  populateUsers,
  getUser,
  getComplementGroup,
  getCompanies,
  query,
  genericRequest,
  syncAll,
};
