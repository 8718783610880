// src/store/services/users.js
import feathersClient, {
  makeServicePlugin,
  BaseModel
} from "../../feathers-client";

class Company extends BaseModel {
  constructor(data, options) {
    super(data, options);
  }
  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = "Company";
  // Define default properties here
  static instanceDefaults() {
    return {
      _id: "",
      branches: [],
      employee: [],
      integrations: {},
      invoices: [],
      isCompanyActive: true,
      isFacebookLoginInactive: false,
      locationTypes: [],
      loyalty: {},
      marketplaceAccount: {},
      name: "",
      owner: "",
      phone: "",
      settings: {},
      settingsOneSignal: {},
      slug: "",
      zoopMarketPlaceAccount: {},
      minimumAge: 12,
      bannerHeader: {},
      allowAllBranchesView: true,
      appUrl: "",
      customMapsApiKeys: {},
      hideCategoriesMenu: false,
      iconMenu: ""
    };
  }
}
const servicePath = "manager/companies";
const servicePlugin = makeServicePlugin({
  Model: Company,
  service: feathersClient.service(servicePath),
  servicePath
});

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
});

export default servicePlugin;
