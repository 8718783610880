const t = "admin";

export default {
  enums: "common/enums",
  branches: `${t}/branches`,
  complementsGroups: `${t}/complements-groups`,
  products: `${t}/products`,
  productsCategories: `${t}/product-categories`,
  periods: `${t}/periods`,
  companies: `${t}/companies`,
  upload: "common/upload",
  orders: `${t}/orders`,
  users: `${t}/users`,
  customers: `${t}/customers`,
  statuses: `${t}/statuses`,
  integrateBee: `${t}/integrate-bee`,
  confirmationCode: "confirmation-code",
  associateMpBranch: `${t}/associate-mp-branch`,
  TotemUsers: `${t}/totem-users`,
  payments: {
    iZettle: {
      encodeToken: "iZettle/encode-iZettle-token",
    },
  },
};
