<template>
  <div class="custom-card">
    <div class="bg"></div>
    <b-card tag="article" class="mb-2 card" :style="{minHeight: height ? `${height}px`: `auto`}">
      <b-img class="mb-1" :src="logo" />
      <b-card-text>{{ description }}</b-card-text>
      <slot></slot>
    </b-card>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "custom-card",
  props: ["description", "height"],
  computed: {
    ...mapState(["logo"])
  }
};
</script>

<style lang="scss" scoped>
.bg {
  background-color: $accent-color;
  height: 45vh;
}

.card {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 400px;
}
</style>